import React, {useEffect, useState} from 'react';
import useCollapse from 'react-collapsed';
import { className } from 'postcss-selector-parser';

import Checkers from '../helpers/class/Checkers';

import SimpleModal from './SimpleModal';
import {btnTextColor} from "../helpers/MethodHelper";

export default function ReadMoreText ( props ) {

  const overviewParagraphsLimit = props.hasOwnProperty('overviewParagraphsLimit') ? props.overviewParagraphsLimit : 1

  const applyRegex = props.hasOwnProperty('applyRegex') ? props.applyRegex : true

  let layout = 1;

  const [ modalState, setModalState ] = useState( "close" );

  const handleShow = ( state ) => {
    setModalState( state );
  }

  const handleHide = () => {
    setModalState( "close" );
  }

  const [ isExpanded, setExpanded ] = useState( false );
  const { getCollapseProps } = useCollapse({
    isExpanded
  });

  let text = null
  if (applyRegex) {
    text = Checkers.isValidString(props.text) ? props.text.match(/<(.)*>.*?<\/\1>/g) : null;
  } else {
    text = Checkers.isValidString(props.text) ? props.text : null;
  }
  let intro_text = '';


  if ( props.text===null ) {
    text = "";
  } else if ( props.text!=undefined && (!Checkers.isValidArray( text ) || text.length==1) ) {
    text = props.text.replace( "<p></p>", "" );
    text = text.split( "</p>" ).filter( Boolean );
  }

  if ( props.text===null ) {
    text = "";
  } else if ( !Checkers.isValidArray( text ) || text.length==1 ) {
    layout = 2;
    intro_text = props.text!=undefined ? props.text.slice( 0, props.letters ) : ''
  }

  return (
    <>
      {
        layout == 1 ?
          Checkers.isValidArray( text ) &&
            <>
              <div className={props.className}>
                {
                  text.map( ( item, key ) => {
                    if ( key < overviewParagraphsLimit ) {
                      return (
                          <>
                            <div key={key.toString()} >
                              <p dangerouslySetInnerHTML={{ __html: item }} />
                            </div>
                          </>
                      )}
                  })
                }
                <div {...getCollapseProps({})}>
                  {
                    text.map( ( item, key ) => {
                      if ( key >= overviewParagraphsLimit ) {
                          return (
                              <>
                                  <div key={key.toString()} >
                                      <p dangerouslySetInnerHTML={{ __html: item }} />
                                  </div>
                              </>
                          )}
                    })
                  }
                </div>
                {
                  Checkers.isValidArray( text ) && text.length > 1 &&
                      <p>
                        <button className="btn-link text-link" onClick={() => setExpanded( ( value ) => !value )}>
                          {
                            isExpanded ? <span className={btnTextColor(props.darkMode)}>Read less...</span> : <span className={btnTextColor(props.darkMode)}>Read more...</span>
                          }
                        </button>
                      </p>
                }
              </div>
            </>
          :
          (
              props.text !=undefined && layout==2 && props.text.length!=intro_text.length ?
              <>
                <div className={props.className}>
                  <p dangerouslySetInnerHTML={{ __html: intro_text+ "..." }}/>
                </div>
                {
                  <p>
                    <button className="btn-link text-link" onClick={() => handleShow( 'distincts_read_more' )}>
                      {
                        isExpanded ? <span className={btnTextColor(props.darkMode)}>Read less...</span> : <span className={btnTextColor(props.darkMode)}>Read more...</span>
                      }
                    </button>
                  </p>
                }

                <SimpleModal
                  modalName={'distincts_read_more'}
                  className="center"
                  Title={props.title}
                  Content={props.text}
                  faIcon={props.faIcon}
                  modalSize="lg"
                  closeModal={() => {
                    handleHide()
                  }}
                  show={modalState === 'distincts_read_more'}
                  updateUri={false}
                />
              </>
              :
                <>
                  <div className={props.className}>
                    <p dangerouslySetInnerHTML={{ __html: intro_text }} />
                  </div>
                </>
          )
      }
    </>
  )
}

import React, { FC, memo, ReactNode, useEffect, useState } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';
import { useRouter } from 'next/router';

interface IProps {
  modalName: string
  className: string
  modalSize?: 'sm' | 'lg' | 'xl'
  closeModal: () => void
  show: boolean
  children?: ReactNode | null
  Title?: ReactNode | null
  Content?: string | null
  faIcon?: string
  updateUri?: boolean
}

const SimpleModal: FC<IProps> =
    (
      {
        modalName,
        className,
        modalSize,
        closeModal,
        show,
        children,
        Title,
        Content,
        faIcon,
        updateUri = true
      }
    ) => {
      const [ showSimpleModal, setShowSimpleModal ] = useState<boolean>( show || false );
      const router = useRouter();
      const hasHash = router.asPath.includes( '#' )

      const openHandler = () => {
        if (updateUri) {
          if (!hasHash) {
            router.push({
              hash: modalName + ''
            }, undefined, {scroll: false})
          }
        }
        setShowSimpleModal( true );
      }

      const closeHandler = () => {
        closeModal();
        setShowSimpleModal( false );
        if (updateUri) {
          if (showSimpleModal) {
            router.back()
          }
        }
      }
      const clickOutSideHandler = () => {
        closeModal();
        setShowSimpleModal( false );
      }


      useEffect( () => {
        if ( show ) openHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [show])

      useEffect( () => {
        if ( !hasHash && showSimpleModal ) clickOutSideHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [hasHash]);

      return (
        <Modal
          className={className}
          size={modalSize}
          show={showSimpleModal}
          onHide={closeHandler}
          animation={true}
          // onOverlayClick={clickOutSideHandler}
        >
          <Modal.Header>
            <CloseButton onClick={closeHandler}/>
            <i className={"m-auto fa-2x " + faIcon}></i>
            <h3 className={'m-auto'}>{Title}</h3>
          </Modal.Header>
          <div className="modal-body">
            <div className="container">
              <div className="row w-100 p-t-20 no-margin text-center">
                <p dangerouslySetInnerHTML={{ __html: Content }} />
              </div>
            </div>
          </div>
          {children}
        </Modal>
      )
    }

export default memo( SimpleModal );

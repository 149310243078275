import React from 'react';
import Image from 'next/image';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/navigation';
import 'swiper/css/pagination';

import CustomModal from '../../CustomModal';

// Import Swiper styles
import 'swiper/css';

// install Swiper modules
SwiperCore.use([ Autoplay, Navigation, Pagination, Lazy ]);

import { Skeleton } from '@mui/material';
import moment from 'moment';

import Checkers from '../../../helpers/class/Checkers';

export default function ArticlesModals ( props ) {
  const article = props.articleModal;

  let subtitle;
  if ( article !== null ) {
    const created = moment( new Date( ( article.created ) * 1000 ) ).format( 'dddd Do MMMM' );
    subtitle = props.type == 'blog' ? created : props.type == 'category' ? article.description : article.subtitle;
  }
  return (
    <CustomModal
      modalName={'article-' + props.modalArticleId}
      className="slide_right"
      modalSize="lg"
      closeModal={() => {
        props.handleHide()
      }}
      show={props.modalShow}
      Title={
        article != null ?
          <>
            <h2 className="text-wrap text-left mt-0">{article.title}</h2>
            <h5
              className="block-title text-left mt-0">{subtitle} {Checkers.isValidString( article.tags ) && ` | ${article.tags}`}</h5>
          </>
          :
          <>
            <Skeleton variant="text" width={'100%'} height={35}/>
            <Skeleton variant="text" width={'80%'} height={20}/>
          </>
      }
    >
      <div className="modal-body">
        <div className="row">
          <div className="col-12">
            {article !== null ?
              <>
                <Swiper
                  speed={1000}
                  navigation={true}
                  pagination={true}
                  slidesPerView={1}
                  autoplay={false}
                  className="mb-3"
                  style={{ height: 310 }}
                >
                  {
                    Checkers.isValidArray( article.images ) &&
                  article.images.map( ( slide, index ) => (
                    Checkers.isValidString( slide.image ) &&
                    <SwiperSlide key={index.toString()}>
                      <Image
                        className="cursor-pointer"
                        priority={index == 0}
                        // loading={key == 0 ? 'eager' : 'lazy'}
                        data-src={slide.image}
                        data-fancybox={'modal-images'}
                        data-thumb={slide.image}
                        layout={'fill'}
                        sizes={'50vw'}
                        quality={'50'}
                        src={slide.image}
                        alt={'Slide-' + index}
                        objectFit={'cover'}
                        placeholder={'blur'}
                        blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                      />
                    </SwiperSlide>
                  ) )
                  }
                </Swiper>
                <div dangerouslySetInnerHTML={{ __html: article.description }}/>
              </>
              :
              <>
                <Skeleton variant="rectangular" width={'100%'} height={310} className="b-rad-md my-4"/>
                <Skeleton variant="text" width={'70%'} height={18}/>
                <Skeleton variant="text" width={'100%'} height={25}/>
                <Skeleton variant="text" width={'100%'} height={25}/>
                <Skeleton variant="text" width={'100%'} height={25}/>
                <Skeleton variant="text" width={'100%'} height={25}/>
              </>
            }
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { faArrowUp as falArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dynamic from 'next/dynamic'

const CollectionsType = dynamic( () => import( './types/CollectionsType' ), { suspense: true });
const MasonryType = dynamic( () => import( './types/MasonryType' ), { suspense: true });
const ColazType = dynamic( () => import( './types/ColazType' ), { suspense: true });
const ListType = dynamic( () => import( './types/ListType' ), { suspense: true });
const TilesType = dynamic( () => import( './types/TilesType' ), { suspense: true });
const ThumbsType = dynamic( () => import( './types/ThumbsType' ), { suspense: true });
const ZigZagType = dynamic( () => import( './types/ZigZagType' ), { suspense: true });
import Skeleton from 'react-loading-skeleton';
import { animateScroll as scroll } from 'react-scroll';
import { Pagination, Stack } from '@mui/material';
import axios from 'axios';
import { useRouter } from 'next/router';

import Checkers from '../../../helpers/class/Checkers';
import * as DataFetchHelper from '../../../helpers/DataFetchHelper';
import ViewPort from '../../ViewPort';

import ArticlesModals from './ArticlesModals';
import {makeStyles} from "@mui/styles";
import {useSelector} from "react-redux";
import Link from "next/link";

interface ArticleTypesInterface {
  type: string
  textColor: string
  content: any
  app_data: any
  builder: any
  viewType: any
  articlesCategories: any
  categoryIdInhrerited?: string
  backgroundColor?: string
  articlesLimit?: number
  serviceType?: string
  itemClassName?: string
  itemWrapperClassName?: string
  articles?: Array<any>
  viewMoreBtnStyle?: undefined|'primary'|'secondary'
  slidesPerView?: number
  showAllLabel?: string
  showFullContent?: boolean
}

export default function ArticleTypes ( props : ArticleTypesInterface ) {

  const showFullContent = props.hasOwnProperty('showFullContent') ? props.showFullContent : false

  const articlesLimitForHomepage = props.content.articlesLimitForHomepage

  const slidesPerView = props.hasOwnProperty('slidesPerView') ? props.slidesPerView : 3

  const showAllLabel = props.hasOwnProperty('showAllLabel') ? props.showAllLabel : "Show all"

  const articlesPerRow = () => {
    switch (viewType) {
      case '400':
      case '576':
        return null
      case '172':
      case '401':
        return 4
      case '173':
      case '402':
        return null
      case '204':
      case '403':
        return 1
      case '395':
      case '404':
        return 6
      case '397':
      case '405':
        return 4
      case '275':
      case '406':
        return 1
    }
  }

  const articlesLimit = () => {
    return props.categoryIdInhrerited != undefined && articlesLimitForHomepage == 1 ? articlesPerRow() * 3 : null
  }

  const router = useRouter();
  const {query} = router;

  let articlesContentInit = {
    type: props.articles!=undefined ? 'article' : 'articles'
  };
  const [articlesContent] = useState(articlesContentInit);

  let container_class = 'container';
  if (props.type == 'blog') {
    if (Checkers.isValidString(props.builder.builder_news_view_width)) {
      container_class = props.builder.builder_news_view_width;
    }
  } else if (props.type == 'category') {
    if (Checkers.isValidString(props.content.category_view_width)) {
      container_class = props.content.category_view_width;
    }
  }

  const builder = props.builder;
  const articlesCategories = props.articlesCategories;
  const viewType = props.viewType;

  const categoryIdToView = props.categoryIdInhrerited != undefined ? props.categoryIdInhrerited : (router.query.category_id!=undefined ? router.query.category_id.toString() : null)

  const paramsInit = {
    page_id: props.app_data.page_id,
    limit: 24,
    offset: 0,
    categories: '',
    category_id: categoryIdToView,
    categoryViewType: Checkers.isValidString(props.content.view_type) ? props.content.view_type : null,
    serviceType: null,
    isHomePage: '0',
    isExperiencesPage: '0'
  };

  // useEffect( () => {
  //   console.log("props.type", props.type)
  // })
  //
  // if (props.type === 'category' || props.type === 'experiences' )
  //   paramsInit.category_id = props.categoryIdInhrerited != undefined ? props.categoryIdInhrerited : router.query.category_id.toString();

  const params = paramsInit;
  const [articles, setArticles] = useState(props.articles!=undefined ? props.articles : []);
  const [articlesCount, setArticlesCount] = useState(0);
  const [loadingArticles, setLoadingArticles] = useState(false);

  const containsCategory = router?.query?.category_id?.length > 1;
  const categoryFromQuery = containsCategory ? router.query.category_id : null;

  const categoryDetails = {
    categoryId:
        Checkers.isValidString(categoryFromQuery) && categoryFromQuery.length > 0 && parseInt(String(categoryFromQuery)) < 1 ? null
            : Checkers.isValidString(categoryFromQuery) && categoryFromQuery.toString().length > 0 ? categoryFromQuery
                : null
    ,
    categoryUrlSlug:
        props.type === 'category' && router.asPath.split('/').length > 1 && !router.asPath.split('/')[1].includes('category') ?
            router.asPath.split('/')[1]
            : router.asPath.split('/blog/').length > 1 && !router.asPath.split('/blog/')[1].includes('category/') ?
                router.asPath.split('/blog/')[1].split('/page').length > 0 ?
                    router.asPath.split('/blog/')[1].split('/page')[0]
                    : router.asPath.split('/blog/')[1]
                : null
  }

  const [categoryDetailsState] = useState(categoryDetails);

  const pageFromPath = router?.asPath.split('page/')[1];
  const [paginationCounter, setPaginationCounter] = useState(Math.ceil(articlesCount / 24));
  const [pageNumber, setPageNumber] = useState(
      Checkers.isValidString(pageFromPath) && pageFromPath.toString().length > 0 && parseInt(pageFromPath) < 1 ? 1
          : Checkers.isValidString(pageFromPath) && pageFromPath.toString().length > 0 ? parseInt(pageFromPath)
              : 1
  );

  const changeCategory = useCallback((category_id, category_url_slug) => {
    categoryDetailsState.categoryId = category_id;
    categoryDetailsState.categoryUrlSlug = category_url_slug;

    let asPath = '/blog';
    let pathname = '/blog'
    let queryParams = {};

    if (Checkers.isValidString(categoryDetailsState.categoryId) || Checkers.isValidString(categoryDetailsState.categoryUrlSlug)) {
      asPath = `/blog/category/${categoryDetailsState.categoryId}`;
      pathname = '';

      if (Checkers.isValidString(categoryDetailsState.categoryUrlSlug)) {
        asPath = `/blog/${categoryDetailsState.categoryUrlSlug}`;
      }

      queryParams = {
        category_id: categoryDetailsState.categoryId,
        page: pageNumber.toString()
      }
    }

    router.replace({
      pathname: pathname,
      query: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        queryParams,
        page: 1
      }
    }, asPath, {shallow: true});
  }, [categoryDetailsState, pageNumber, router]);

  const setUrlPage = useCallback((page = 1) => {

    let asPath = '';
    let queryParams;

    asPath = router.asPath;
    if (asPath.split('/page/').length > 1)
      asPath = router.asPath.split('/page/')[0];

    if (Checkers.isValidString(categoryDetailsState.categoryId) || Checkers.isValidString(categoryDetailsState.categoryUrlSlug)) {
      queryParams = {
        category_id: categoryDetailsState.categoryId,
        page: page.toString()
      }
    } else {
      queryParams = {
        page: page.toString()
      }
    }

    if (page > 1)
      asPath += `/page/${page}`

    router.push({
      query: {
        ...query,
        queryParams
      }
    }, asPath, {shallow: true});
  }, [categoryDetailsState.categoryId, categoryDetailsState.categoryUrlSlug, query, router])

  const scrollToTop = useCallback((page) => {
    setPageNumber(page);
    let barOffset = 0;
    const element = document.getElementById('articles_top');
    const scrollToYOffset = element.getBoundingClientRect().y + window.scrollY - barOffset;
    scroll.scrollTo(scrollToYOffset);

    setUrlPage(page);

  }, [setUrlPage]);

  const pageFunction = query.page ? parseInt(query.page as string) : 1;

  const isHomePage = useSelector(state => state['appSettings']['isHomepage'])

  const isExperiencesPage = useSelector(state => state['appSettings']['isExperiencesPage'])

  const fetchArticles = useCallback((page = pageFunction) => {
    if (props.articles!=undefined) {
      return
    }

    setLoadingArticles(true);
    // setUrlPage( page );
    params.offset = (page - 1) * 24;

    if ( props.articlesLimit!=undefined || props.articlesLimit!=null ) {
      params.limit = props.articlesLimit
      params.offset = 0
    }

    if (props.type === 'blog')
      params.categories = categoryDetailsState?.categoryId?.toString();

    if (props.type === 'services') {
      params.serviceType = props.serviceType
    }

    let useCache = '';
    if (props.type === 'category') {
      useCache = '1'
    }

    params.isHomePage = isHomePage

    params.isExperiencesPage = isExperiencesPage

    axios.post(`/apiloggia`, {
      action: `/api/lodge/frontend/get/${props.type}/articles?${DataFetchHelper.EncodeQueryData(params)}`,
      useCache: useCache
    }).then(r => {
      let responseArticles = Checkers.objChildExists(r.data, 'articles') ? r.data.articles : r.data.subcategories;
      if ( props.type=='experiences' ){
        articlesContent.type = 'experience';
      } else {
        if (Checkers.objChildExists(r.data, 'articles')) {
          articlesContent.type = 'article';
        } else {
          articlesContent.type = 'category';
        }
      }
      setArticles(responseArticles);
      setArticlesCount(prev => prev = r.data.count);
      if (params.offset > r.data.count) {
        fetchArticles(1);
      }
      setPaginationCounter(Math.ceil(r.data.count / 24));
      setLoadingArticles(false);
    });

  }, [categoryDetailsState?.categoryId, pageFunction, params, props.type]);

  const filterArticlesByCategories = useCallback((event) => {
    if (document !== undefined) {
      const activeListEl = document.querySelector('.filters ul>li a.active');
      if (activeListEl)
        activeListEl.classList.remove('active');
    }
    event.target.classList.add('active');

    setPageNumber(1);
    fetchArticles(1);

  }, [fetchArticles]);

  const setClass = (article, item_class) => {
    switch (articlesContent.type) {
      case 'category':
        item_class += '\ cursor-pointer';
        return item_class;
      case 'experience':
      case 'article':
      case 'blog':
        if (Checkers.isValidString(article.redirect_url)) {
          item_class += '\ cursor-pointer';
        } else if (article.show_in_different_page && Checkers.isValidString(article.description)) {
          item_class += '\ cursor-pointer';
        } else if (Checkers.isValidString(article.description)) {
          item_class += '\ cursor-pointer';
        }
        return item_class;
    }
  };

  const [modalArticleId, setModalArticleId] = useState(0);
  const [articleModal, setArticleModal] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const handleShow = () => {
    setModalShow(true);
  }
  const handleHide = () => {
    setModalShow(false);
    setArticleModal(null);
  }
  const fetchModalContent = useCallback((article) => {
    const modal_params = {
      article_id: article.id,
      page_id: props.app_data.page_id,
      type: props.type
    }
    setModalArticleId(article.id);
    handleShow();
    // console.log("serviceUrl", `/api/lodge/frontend/get/article/modal/content?${DataFetchHelper.EncodeQueryData(modal_params)}`)
    axios.post(`/apiloggia`, {
      action: `/api/lodge/frontend/get/article/modal/content?${DataFetchHelper.EncodeQueryData(modal_params)}`,
      useCache: false
    }).then(response => {
      setArticleModal(response.data);
    });
  }, [setArticleModal, props.app_data.page_id]);

  const clickHandler = (article) => {
    switch (articlesContent.type) {
      case 'article':
      case 'blog':
        if (
            !Checkers.isValidString(article.redirect_url) &&
            !(article.show_in_different_page && Checkers.valueToBoolean(article.has_description || article.has_body)) &&
            Checkers.valueToBoolean(article.has_description)
        ) {
          fetchModalContent(article);
        }
        break;
    }
  }

  const updateHref = (article) => {
    let hrefUpdated = ''

    switch (articlesContent.type) {
      case 'experience':
        // event.currentTarget.href = `${window.origin}/`;
        if (Checkers.isValidString(article.urlSlug))
          hrefUpdated = article.urlSlug;
        else if (Checkers.isValidString(article.loggiaUrl))
          hrefUpdated = article.loggiaUrl;
        else
          hrefUpdated = `/category/${article.id}`
        break;
      case 'category':
        // event.currentTarget.href = `${window.origin}/`;
        if (Checkers.isValidString(article.loggiaUrl))
          hrefUpdated = article.loggiaUrl;
        else
          hrefUpdated = `/category/${article.id}`
        break;
      case 'article':
        if (Checkers.isValidString(article.redirect_url)) {
          hrefUpdated = article.redirect_url;
        } else if (article.show_in_different_page && Checkers.valueToBoolean(article.has_description)) {
          if (Checkers.isValidString(article.loggiaUrl)) {
            hrefUpdated = `/${article.loggiaUrl}`;
          } else {
            hrefUpdated = `/${articlesContent.type}/${article.id}`;
          }

        }
        // else if (Checkers.valueToBoolean(article.has_description)) {
          // event.currentTarget.removeAttribute("href");
        // }
        break;
      case 'blog':
        if (Checkers.isValidString(article.redirect_url)) {
          hrefUpdated = article.redirect_url;
        } else if (article.show_in_different_page && Checkers.valueToBoolean(article.has_description)) {
          hrefUpdated = `${articlesContent.type}/${article.id}`;
        } /*else if (Checkers.valueToBoolean(article.has_description)) {
          event.currentTarget.removeAttribute("href");
        }*/
        break;
    }

    if (hrefUpdated.startsWith('https://') || hrefUpdated.startsWith('http://') ) {
      //do nothing
    } else if (hrefUpdated.length==0) {
      hrefUpdated = '#/'
    }
    else if (!hrefUpdated.startsWith('/')) {
      hrefUpdated = '/' + hrefUpdated
    }

    return hrefUpdated
  }

  const tilesBackgroundColor = Checkers.isHexColor(props.content.tilesBackgroundColor) ? props.content.tilesBackgroundColor : null

  const tilesTextDarkMode = props.content.tilesTextDarkMode

  const viewTypeSwitch = (viewType) => {
    switch (viewType) {
      case '400':
      case '576':
        return (
            <Suspense>
              <CollectionsType
                  type={props.type}
                  articlesContentType={articlesContent.type}
                  builder={builder}
                  content={props.content}
                  articles={articles}
                  clickHandler={clickHandler}
                  setClass={setClass}
                  updateHref={updateHref}
                  loadingArticles={loadingArticles}
                  itemClassName={props.itemClassName}
                  itemWrapperClassName={props.itemWrapperClassName}
                  slidesPerView={slidesPerView}
              />
            </Suspense>
        )
      case '172':
      case '401':
        return (
            <Suspense>
              <MasonryType
                  type={props.type}
                  articlesContentType={articlesContent.type}
                  builder={builder}
                  content={props.content}
                  articles={articles}
                  clickHandler={clickHandler}
                  setClass={setClass}
                  updateHref={updateHref}
                  loadingArticles={loadingArticles}
                  textColor={tilesTextDarkMode}
                  backgroundColor={tilesBackgroundColor}
                  itemClassName={props.itemClassName}
                  itemWrapperClassName={props.itemWrapperClassName}
                  articlesLimit={articlesLimit()}
              />
            </Suspense>
        )
      case '173':
      case '402':
        return (
            <Suspense>
              <ColazType
                  type={props.type}
                  articlesContentType={articlesContent.type}
                  builder={builder}
                  articles={articles}
                  content={props.content}
                  clickHandler={clickHandler}
                  setClass={setClass}
                  updateHref={updateHref}
                  loadingArticles={loadingArticles}
              />
            </Suspense>
        )
      case '204':
      case '403':
        return (
            <Suspense>
              <ListType
                  type={props.type}
                  articlesContentType={articlesContent.type}
                  builder={builder}
                  content={props.content}
                  articles={articles}
                  clickHandler={clickHandler}
                  setClass={setClass}
                  updateHref={updateHref}
                  loadingArticles={loadingArticles}
                  textColor={tilesTextDarkMode}
                  backgroundColor={tilesBackgroundColor}
                  itemClassName={props.itemClassName}
                  itemWrapperClassName={props.itemWrapperClassName}
                  articlesLimit={articlesLimit()}
              />
            </Suspense>
        )
      case '395':
      case '404':
        return (
            <Suspense>
              <TilesType
                  type={props.type}
                  articlesContentType={articlesContent.type}
                  builder={builder}
                  content={props.content}
                  articles={articles}
                  clickHandler={clickHandler}
                  setClass={setClass}
                  updateHref={updateHref}
                  loadingArticles={loadingArticles}
                  itemClassName={props.itemClassName}
                  itemWrapperClassName={props.itemWrapperClassName}
                  articlesLimit={articlesLimit()}
              />
            </Suspense>
        )
      case '397':
      case '405':
        return (
            <Suspense>
              <ThumbsType
                  type={props.type}
                  articlesContentType={articlesContent.type}
                  content={props.content}
                  builder={builder}
                  articles={articles}
                  clickHandler={clickHandler}
                  setClass={setClass}
                  updateHref={updateHref}
                  loadingArticles={loadingArticles}
                  // textColor={props.textColor}
                  textColor={tilesTextDarkMode}
                  backgroundColor={tilesBackgroundColor}
                  itemClassName={props.itemClassName}
                  itemWrapperClassName={props.itemWrapperClassName}
                  articlesLimit={articlesLimit()}
              />
            </Suspense>
        )
      case '275':
      case '406':
        return (
            <Suspense>
              <ZigZagType
                  type={props.type}
                  textColor={props.textColor}
                  articlesContentType={articlesContent.type}
                  articles={articles}
                  content={props.content}
                  builder={builder}
                  clickHandler={clickHandler}
                  setClass={setClass}
                  updateHref={updateHref}
                  loadingArticles={loadingArticles}
                  itemClassName={props.itemClassName}
                  itemWrapperClassName={props.itemWrapperClassName}
                  articlesLimit={articlesLimit()}
                  showFullContent={showFullContent}
              />
            </Suspense>
        )
    }
  }

  useEffect( () => {
    // console.log("debugTypes", viewType, props.type, articles)
  })

  useEffect(() => {
    fetchArticles(pageNumber);
  }, [categoryIdToView]);

  useEffect(() => {
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleModal]);

  const [ref] = useHookWithRefCallback(categoryDetailsState.categoryId);
  return (
      <>
        {
            Checkers.isValidArray(articlesCategories) &&
            <div className="bg-master-lightest pb-4" style={{ background: props.backgroundColor != undefined && Checkers.isHexColor(props.backgroundColor) ? props.backgroundColor : ""  }} >
              <div className="container">
                <div className="row justify-content-center border-top filters">
                  <div className="col-12 col-sm-auto text-center">
                    <ul className="no-style m-0 py-1 py-sm-3 text-center">
                      <ViewPort
                          parentClassName={'inline px-2'}
                          skeletonItem={
                            <>
                              <div className="col-auto p-0">
                                <Skeleton height={15} width={80} className="col-12"/>
                              </div>
                            </>
                          }
                      >
                        <li ref={ref} id="all-categories" className="col-auto inline text-center py-2 py-sm-0 m-0 px-2">
                          <a className={"link text-black fs-13 block-title cursor-pointer " + props.textColor }
                             onClick={(event) => {
                               changeCategory(null, null);
                               filterArticlesByCategories(event);
                             }}
                          >All</a>
                        </li>
                      </ViewPort>
                      {
                        articlesCategories.map((category, index) => {
                          let width = 95
                          if (index % 2 == 0) {
                            width = 65;
                          } else if (index % 3 == 0) {
                            width = 80;
                          }
                          return (
                              <ViewPort
                                  parentClassName={'inline px-2 mb-2'}
                                  key={category.id.toString()}
                                  skeletonItem={
                                    <div className="col-auto p-0">
                                      <Skeleton height={15} width={width} className="col-12"/>
                                    </div>
                                  }
                              >
                                <li ref={ref} id={`category-${category.id}`} key={category.id.toString()}
                                    className="col-auto inline text-center py-2 py-sm-0 m-0 px-2">
                                  <a className={"link text-black fs-13 block-title cursor-pointer "  + props.textColor }
                                     onClick={(event) => {
                                       changeCategory(category.id.toString(), category.urlSlug);
                                       filterArticlesByCategories(event);
                                     }}
                                  >{category.title}</a>
                                </li>
                              </ViewPort>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
        }
        <div className="">
          {
            viewTypeSwitch(viewType)
          }
          {
            articlesLimit() != null && Checkers.isValidPositiveNumber(articlesLimit()) &&
              <>
                <div className="container text-center p-b-40">
                  <div className="row justify-content-center">
                    <div className="col-auto text-center">
                      {
                        props.viewMoreBtnStyle == 'primary' ?
                            <Link href={"/" + props.content.urlSlug}>
                              <a className="btn btn-primary btn-design-color p-4 p-t-10 p-b-10">{showAllLabel}</a>
                            </Link>
                            :
                            <Link href={"/" + props.content.urlSlug}>
                              <a className="btn btn-rounded btn-black btn-bordered">{showAllLabel}</a>
                            </Link>
                      }
                    </div>
                  </div>
                </div>
              </>
          }
          {
            // props.content.urlSlug, props.type
            (isExperiencesPage || isHomePage) && props.type == 'experiences' && articlesCount > 0 ?
                <>
                  <div className="container text-center p-b-40">
                    <div className="row justify-content-center">
                      <div className="col-auto text-center">
                        {
                          props.viewMoreBtnStyle == 'primary' ?
                              <Link href={"/" + (Checkers.isValidString(props.content.urlSlug) ? props.content.urlSlug : "excursions/" + props.content.id) }>
                                <a className="btn btn-primary btn-design-color p-4 p-t-10 p-b-10">
                                  {showAllLabel}
                                </a>
                              </Link>
                              :
                              <Link href={"/" + (Checkers.isValidString(props.content.urlSlug) ? props.content.urlSlug : "excursions/" + props.content.id) }>
                                <a className="btn btn-rounded btn-black btn-bordered">{showAllLabel}</a>
                              </Link>
                        }
                      </div>
                    </div>
                  </div>
                </>
                :
                <></>
          }
        </div>

        {props.articlesLimit ==undefined && articlesCount > 24 && paginationCounter > 1 &&
            <div className="">
              <div className={container_class}>
                <div className="row w-100 m-0 py-5 justify-content-center">
                  <div className="col-auto">
                    <Stack spacing={2}>
                      <Pagination
                          page={pageNumber}
                          classes={{
                            ul: props.textColor
                          }}
                          count={paginationCounter}
                          onChange={(event, page) => {
                            scrollToTop(page);
                            fetchArticles(page);
                          }}
                          getItemAriaLabel={(button, number) => {
                            switch (button) {
                              case 'page':
                                return `${number}`
                              case 'previous':
                                return 'previous'
                              case 'next':
                                return 'next'
                            }
                          }}
                      />
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
        }

        {/*<button*/}
        {/*    type="button"*/}
        {/*    className="btn btn-lg btn-primary btn-design-color rounded-circle shadow-5 z-index-5"*/}
        {/*    id="btn-back-to-top"*/}
        {/*>*/}
        {/*  <FontAwesomeIcon className="fs-16" icon={falArrowUp}/>*/}
        {/*</button>*/}
        <ArticlesModals
            type={articlesContent.type}
            modalShow={modalShow}
            articleModal={articleModal}
            modalArticleId={modalArticleId}
            handleHide={handleHide}
        />
      </>
  )
}

function useHookWithRefCallback ( categoryId=null ) {
  const ref = useRef(null)
  const setRef = useCallback(node => {
    if (ref.current) {
      if (categoryId == null && ref.current.id == 'all-categories') {
        ref.current.children[0].classList.add('active');
      } else if (categoryId > 0) {
        if (String(ref.current.id.split('-')[1]) == String(categoryId)) {
          ref.current.children[0].classList.add('active');
        }
      }
    }

    // Save a reference to the node
    ref.current = node
  }, [])

  return [setRef]
}

import React, { useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Checkers from '../../helpers/class/Checkers';
import SwiperColorClasses from '../plugins/swiperUtils';

import Motto from './Motto';

// Import Swiper styles
import 'swiper/css';

// install Swiper modules
SwiperCore.use([ Autoplay, Navigation, Pagination, Lazy ]);

export default function FeaturedImages ( props ) {

  const autoplay = {
    delay: 2500,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  };

  const swiper_options = {
    mode: 'white'
  };
  const router = useRouter();
  useEffect( () => {
    if ( router.route.includes( '/blog' ) ) {
      if ( window !== undefined ) {
        swiper_options.mode = window.designMode == 1 ? 'white' : 'dark';
      }
      new SwiperColorClasses( swiper_options ).swiperOptions();
    }
  });

  return (
    <>
      <Swiper
        speed={1000}
        navigation={true}
        pagination={true}
        slidesPerView={1}
        autoplay={ props.jumbotronAutoplay ? autoplay : false }
      >
        {
          props.album !== undefined ?
            props.album !== null && props.album.length > 0 ?
              props.album.map( ( slide, key ) => (
                <SwiperSlide key={key}>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <Image
                    priority={key == 0}
                    // loading={key == 0 ? 'eager' : 'lazy'}
                    data-fancybox={Checkers.isValidString( props.dataFancybox ) ? props.dataFancybox : ''}
                    data-src={Checkers.booleanState( props.fancybox ) && slide.image}
                    data-thumb={Checkers.booleanState( props.fancybox ) && slide.image}
                    layout={'fill'}
                    sizes={'75vw'}
                    quality={'75'}
                    src={slide.image}
                    alt={'Slide-' + key}
                    placeholder={'blur'}
                    blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                  />
                  {
                    key === 0 &&
                      <Motto
                        isDesktop={props.isDesktop}
                        motto={props.motto}
                        logoDecoration={props.logoDecoration}
                        logoDecorationOriginWidth={props.logoDecorationOriginWidth}
                        logoDecorationOriginHeight={props.logoDecorationOriginHeight}
                        jumbotronTextAlign={props.jumbotronTextAlign}
                        jumbotronTextColor={props.jumbotronTextColor}
                        sliderTitleShadow={props.sliderTitleShadow}
                        sliderTitleBold={props.sliderTitleBold}
                        homeTitle={props.homeTitle}
                        homeSubtitle={props.homeSubtitle}
                      />
                  }
                  {
                    props.jumbotronOverlayOpacity > 0 &&
                      <div className="bg-overlay z-index-1" style={{ backgroundColor: 'rgb(0, 0, 0)', opacity: props.jumbotronOverlayOpacity }}/>
                  }
                </SwiperSlide>
              ) )
              :''
            :''
        }
      </Swiper>
    </>
  )
}

import {FC, useEffect} from "react";
import Checkers from "../../../../helpers/class/Checkers";
import SwipperWrapper from "./SwipperWrapper";
import SimpleContent from "./SImpleContent";
import * as React from "react";
import AttributesList from "./AttributesList";
import FeaturesList from "./FeaturesList";
import BedsArrangement from "./BedsArrangement";
import Skeleton from "react-loading-skeleton";
import {isDarkMode, textColor} from "../../../../helpers/MethodHelper";

interface TabsContentWrapperProps {
    data: Array<any>,
    ReactComponentsArrayLoading: boolean
    tabsContentClass?: string
    backgroundColor?: string
    darkMode?: boolean|number
    tabsMode?: 'fillUp'|'lineTriangle'
    responsiveDropdown?: boolean
}
const TabsContentWrapper: FC<TabsContentWrapperProps> = (
    {
        data, ReactComponentsArrayLoading, tabsContentClass, backgroundColor, darkMode, tabsMode= 'lineTriangle', responsiveDropdown = true
    }) => {

    // <div className="card card-transparent  m-b-0">
    //     {/*<div className={"nav-tabs-header " + (isDarkMode(darkMode) ? "nav-tabs-dark-mode" : "") }>*/}
    //     <ul className="nav nav-tabs nav-tabs-fillup hidden-sm hidden-xs"

    return (
        <>
            {
                !ReactComponentsArrayLoading ?
                    <>
                        <section style={{background: Checkers.isHexColor(backgroundColor)? backgroundColor : ''}}>
                            <div className={'container p-0'}>
                                <div className="row w-100 no-margin">
                                    <div className="col-md-12 no-padding">
                                        <div className="panel panel-transparent m-b-0">
                                            {
                                                tabsMode == 'fillUp' &&
                                                <>
                                                    <div className={"nav-tabs-header " + (isDarkMode(darkMode) ? "nav-tabs-dark-mode" : "") }>
                                                        <ul className={"nav nav-tabs nav-tabs-fillup " + (responsiveDropdown ? "hidden-sm hidden-xs" : "" )}
                                                            role={responsiveDropdown ? "tablist" : ''}
                                                            data-init-reponsive-tabs={responsiveDropdown ? "dropdownfx" : ''} >
                                                            {
                                                                data.map((tab, i) => {
                                                                    return (
                                                                        <>
                                                                            <li>
                                                                                <a className={textColor(darkMode) + " fs-12 " + (i == 0 ? "active" : "")}
                                                                                   data-toggle="tab" href={"#slide" + tab.tabId}
                                                                                   data-bs-toggle="tab"
                                                                                   data-bs-target={"#slide" + tab.tabId}>
                                                                                    <span>{tab.tabTitle}</span>
                                                                                </a>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </>
                                            }
                                            {
                                                tabsMode == 'lineTriangle' &&
                                                <>
                                                    <div className={"nav-tabs-header nav-tabs-linetriangle " + (isDarkMode(darkMode) ? "nav-tabs-dark-mode" : "") }>
                                                        <ul className={"nav nav-tabs w-100 nav-tabs-linetriangle " + (responsiveDropdown ? "hidden-sm hidden-xs" : "" )}
                                                            style={{width: "auto", whiteSpace: "nowrap", display: "inline-block"}}
                                                            role={responsiveDropdown ? "tablist" : ''}
                                                            data-init-reponsive-tabs={responsiveDropdown ? "dropdownfx" : ''} >
                                                            {
                                                                data.map((tab, i) => {
                                                                    return (
                                                                        <>
                                                                            <li>
                                                                                <a className={textColor(darkMode) + " fs-12 " + (i == 0 ? "active" : "")}
                                                                                   data-toggle="tab" href={"#slide" + tab.tabId}
                                                                                   data-bs-toggle="tab"
                                                                                   data-bs-target={"#slide" + tab.tabId}>
                                                                                    <span>{tab.tabTitle}</span>
                                                                                </a>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </>
                                            }
                                            <div className={"tab-content " + (Checkers.isValidString(tabsContentClass) ? tabsContentClass : '')} style={{background: "transparent"}}>
                                                {
                                                    data.map((tab, i) => {
                                                        return (
                                                            <>
                                                                <div className={"tab-pane fade " + (i == 0 ? "show active" : "")}
                                                                     id={"slide" + tab.tabId}>
                                                                    <>
                                                                        {
                                                                            tab.tabContent
                                                                        }
                                                                    </>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                    :
                    <>
                        <div className="row w-100 no-margin">
                            <div className="col-md-12 no-padding">
                                <div className="panel panel-transparent m-b-0">
                                    <Skeleton height={30} className="col-8 col-sm-5 col-md-3 b-rad-md mb-1"/>
                                    <Skeleton height={42} className="col-9 col-sm-7 col-md-5 b-rad-md mb-2"/>
                                    <Skeleton height={180} className="col-12 b-rad-lg"/>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default TabsContentWrapper